import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 分公司关联人员档案表查询
export const industryInfoPage = (data) => {
    return request({
        url: apiCrmHost + '/industry/info/page',
        method: 'post',
        data
    });
};
