export const config = {
    name: 'industryList',
    searchFields: [
        {
            name: '行业大类',
            value: 'industryCategoriesName',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '行业类型名称',
            value: 'industryTypeName',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '行业类型编码',
            value: 'industryTypeCode',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '行业销售支持',
            value: 'industrySalesSupportMipName',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusId', 'value': 'ID' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名', 'displayName': 'esusUserNameCn' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#industrySalesSupportMipName', 'esusLoginName#industrySalesSupportMip'],
            beforeRequest: (params) => {
                const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.esusUserNameCn = inputKey;
                    params.esusLoginName = null;
                } else {
                    params.esusLoginName = inputKey;
                    params.esusUserNameCn = null;
                }
                return params;
            }
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: '行业大类',
                prop: 'industryCategoriesName',
                minWidth: 150
            },
            {
                label: '行业大类编码',
                prop: 'industryCategoriesCode',
                minWidth: 150
            },
            {
                label: '行业类型',
                prop: 'industryTypeName',
                minWidth: 150
            },
            {
                label: '行业类型编码',
                prop: 'industryTypeCode',
                minWidth: 150
            },
            {
                label: '行业销售支持',
                prop: 'industrySalesSupportMipName',
                minWidth: 150
            },
            {
                label: '行业销售支持mip',
                prop: 'industrySalesSupportMip',
                minWidth: 150
            },
            {
                label: '创建人',
                prop: 'createUserName',
                minWidth: 150
            },
            {
                label: '创建人mip',
                prop: 'createUserCode',
                minWidth: 150
            },
            {
                label: '创建时间',
                prop: 'createTime',
                sortable: true,
                minWidth: 154
            },
            {
                label: '修改人',
                prop: 'updateUserName',
                minWidth: 150
            },
            {
                label: '修改人mip',
                prop: 'updateUserCode',
                minWidth: 150
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                minWidth: 150
            }
        ]
    }
};
